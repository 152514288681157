import React from 'react';
import { map } from 'lodash';
import { Grid } from '@mui/joy';
import { useChartRequiredUtils } from 'src/components/Charts/common';
import { useCostByDepartment } from 'src/modules/utils';
import ColumnChart from 'src/components/Charts/2d/ColumnChart';
import {
  ChartContainer,
  ChartContainerBody,
  ChartContainerHeader,
  ChartContainerToolbar,
  ChartContainerUtils,
} from 'src/components/Charts/ChartContainer';
import { costsByDepartmentChartHeight } from 'src/pages/Payboard/Costs/costs.constants';
import { MUI_SIZE_1, MUI_SIZE_12, MUI_SIZE_6 } from 'src/config';
import { MainContentLoader } from 'src/components/Layout/components/PageTour/MainContentLoader';
import { PageContentChildContainer } from 'src/components/PageContent';
import { RequiredFiltersAlert } from 'src/components/RequiredFiltersAlert';

interface ICostByDepartmentProps {
  siteId?: number;
  yearWeekRange?: { from: number; to: number };
}

export const CostByDepartment: React.FC<ICostByDepartmentProps> = ({
  yearWeekRange,
  siteId,
}) => {
  const { chartRef, exportChart, toggleFullScreen } = useChartRequiredUtils();

  const { series, categories, isDataLoading } = useCostByDepartment({
    siteId,
    yearWeekRange,
  });

  const charts = React.useMemo(
    () =>
      map(series, (v, siteName) => (
        <Grid key={siteName} xs={MUI_SIZE_12} md={MUI_SIZE_6}>
          <ChartContainer>
            <ChartContainerHeader title={siteName}>
              <ChartContainerToolbar>
                <ChartContainerUtils
                  onToggleFullScreen={toggleFullScreen}
                  onExport={() =>
                    exportChart(`costs_by_department_${siteName}`)
                  }
                />
              </ChartContainerToolbar>
            </ChartContainerHeader>
            <ChartContainerBody isLoading={isDataLoading}>
              <ColumnChart
                ref={chartRef}
                height={costsByDepartmentChartHeight}
                categories={categories}
                series={Object.values(v)}
              />
            </ChartContainerBody>
          </ChartContainer>
        </Grid>
      )),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [categories, isDataLoading, series],
  );

  console.log({ isDataLoading });

  return (
    <PageContentChildContainer sx={{ px: 0, pt: 1 }}>
      {!yearWeekRange && (
        <RequiredFiltersAlert
          i18nKey="payboard.cost.by_department.site_filter_alert"
          values={{ yearWeek: 'Year Week' }}
        />
      )}

      {isDataLoading && (
        <PageContentChildContainer sx={{ position: 'relative' }}>
          <MainContentLoader />
        </PageContentChildContainer>
      )}

      {!isDataLoading && yearWeekRange && (
        <Grid container spacing={MUI_SIZE_1}>
          {charts}
        </Grid>
      )}
    </PageContentChildContainer>
  );
};
