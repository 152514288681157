import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import EnhancedTable, {
  HeadCell,
  ITableSyncProps,
} from '../../../components/EnhancedTable';
import {
  exportEmployeeSkillRequest,
  getEmployeeSkillCountRequest,
  getEmployeeSkillListRequest,
} from '../../../modules/actions';
import {
  getEmployeeSkillCount,
  getEmployeeSkillTable,
  getIsEmployeeSkillsDataLoading,
} from '../../../modules/selectors/employeeSkill';
import { ITableFilter } from '../../../components/EnhancedTable/EnhancedTableFilter';
import { IdsArray } from '../../../modules/types';
import {
  useCreateExportProps,
  useFetchSkillsCombobox,
  useFilter,
  useEmployeeSkillsDefaultFilters,
  useFetchShiftsCombobox,
} from '../../../modules/utils/hooks';
import { map } from 'lodash';
import {
  ModelsToDelete,
  skillLevels,
  skillWarnings,
  useHasUserAccessToAction,
} from '../../../config';
import { ComboBoxOption } from '../../../components/ComboBox';
import { getSkillsComboboxList } from '../../../modules/selectors/skill';
import { useTranslation } from 'react-i18next';
import { transformToCapitalize } from '../../../modules/utils/helpers/common';
import {
  PageContentChildContainer,
  PageContentWithTopToolbar,
} from 'src/components/PageContent';
import { ThreeDotsMenu } from 'src/components/ThreeDotsMenu';
import { IFilesDownloaderFileData } from 'src/components/FilesDownloaderDrawer/FilesDownloaderDrawerFile';
import { FilesDownloaderDrawer } from 'src/components/FilesDownloaderDrawer';
import { UpdateEmployeesSkills } from './EmployeesSkillFlexUpdate';
import { useTheme } from '@mui/joy';
import { manageEntitiesConfig } from 'src/config/manageEntitiesConfig';
import { getShiftOptionsByGloballySites } from 'src/modules/selectors/shift';

export const EmployeesSkillFlex = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  const [
    isAttachmentsRequestsInProgress,
    setIsAttachmentsRequestsInProgress,
  ] = React.useState(false);

  const [selectedEmployeesSkills, setSelectedEmployeesSkills] = React.useState<
    IdsArray
  >([]);

  const [
    isUpdateEmployeesSkillFlexVisible,
    setIsUpdateEmployeesSkillFlexVisible,
  ] = React.useState(false);

  const [attachmentToShow, setAttachmentToShow] = React.useState<
    Array<IFilesDownloaderFileData>
  >([]);

  const fetchShiftsCombobox = useFetchShiftsCombobox();

  useEffect(() => {
    fetchShiftsCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const shifts = useSelector(getShiftOptionsByGloballySites, shallowEqual);

  const allowedToUpdateEmployeeSkill = useHasUserAccessToAction(
    manageEntitiesConfig.employee_skillFlex.update.id,
  );

  const defaultFilter = useEmployeeSkillsDefaultFilters();

  // fetch Supervisor list
  const list = useSelector(getEmployeeSkillTable);

  const isTableDataLoading = useSelector(getIsEmployeeSkillsDataLoading);

  const fetchSkillsCombobox = useFetchSkillsCombobox();

  const count = useSelector(getEmployeeSkillCount);
  const exportProps = useCreateExportProps(exportEmployeeSkillRequest);

  // create dispatcher
  const dispatcher = useDispatch();

  const { filterCount, filterList } = useFilter(defaultFilter);

  // fetch sites list from store
  const skills = useSelector(getSkillsComboboxList);

  // compose table header cells
  const headCells: HeadCell[] = [
    {
      id: 'employee.firstName',
      disablePadding: false,
      label: t('emp_skills.emp_first_name'),
    },
    {
      id: 'employee.lastName',
      disablePadding: false,
      label: t('emp_skills.emp_last_name'),
    },
    { id: 'skill.name', disablePadding: false, label: t('emp_skills.skill') },
    {
      id: 'employee.defaultShift.name',
      disablePadding: false,
      label: t('emp_skills.defaultShift'),
    },
    { id: 'level', disablePadding: false, label: t('emp_skills.level') },
    {
      id: 'trainingDate',
      disablePadding: false,
      label: t('emp_skills.training_date'),
    },
    {
      id: 'expirationDate',
      disablePadding: false,
      label: t('emp_skills.expiration_date'),
    },
    {
      id: 'attachment',
      disablePadding: false,
      disableSorting: true,
      label: t('emp_skills.attachment'),
    },
  ];

  // table filters
  const filters: ITableFilter[] = [
    {
      name: 'employee.id',
      label: t('emp_skills.employee'),
      operator: 'eq',
      type: 'comboboxEmployee',
      propertyAsID: 'id',
    },
    {
      name: 'skill.id',
      label: t('emp_skills.skill'),
      operator: 'eq',
      type: 'combobox',
      options: skills as ComboBoxOption[],
    },
    {
      name: 'employee.defaultShiftId',
      label: t('emp_skills.defaultShift'),
      operator: 'eq',
      type: 'combobox',
      options: shifts as ComboBoxOption[],
    },
    {
      name: 'level',
      label: t('emp_skills.level'),
      operator: 'eq',
      type: 'combobox',
      options: map(
        skillLevels,
        (skillLevel) =>
          (({
            id: skillLevel,
            name: skillLevel,
          } as unknown) as ComboBoxOption),
      ),
    },
    {
      name: 'warning',
      label: t('emp_skills.warning'),
      operator: 'eq',
      type: 'combobox',
      options: map(
        skillWarnings,
        (skillWarning) =>
          (({
            id: skillWarning,
            name: skillWarning,
          } as unknown) as ComboBoxOption),
      ),
    },
    {
      name: 'employee.active',
      label: t('employees.active'),
      operator: 'eq',
      type: 'buttonGroup',
      // filterPathInQueryParams: 'filter.include[1].scope.where.active',
      config: {
        buttons: [
          { value: undefined, label: t('common.all') },
          { value: true, label: t('common.active') },
          { value: false, label: t('common.unactive') },
        ],
      },
    },
  ];

  // make request to fetch employeeSkills when component is mounted
  useEffect(() => {
    dispatcher(getEmployeeSkillListRequest(filterList));
    // get skills combobox
    fetchSkillsCombobox();
    // get total count
    dispatcher(getEmployeeSkillCountRequest(filterCount));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultFilter]);

  // handle table synchronization
  const onSync = (props: ITableSyncProps) => {
    const { order, page, rowsPerPage, where, include: newInclude } = props;
    const offset = page * rowsPerPage;
    dispatcher(
      getEmployeeSkillListRequest({
        filter: {
          limit: rowsPerPage,
          offset,
          where,
          order,
          include: newInclude,
        },
      }),
    );
    // update count accordingly to applied filters
    dispatcher(
      getEmployeeSkillCountRequest({ filter: { where, include: newInclude } }),
    );
  };

  // handle updating
  const handleUpdating = (ids: IdsArray) => {
    setSelectedEmployeesSkills(ids);
    setIsUpdateEmployeesSkillFlexVisible(true);
  };

  const computedList = React.useMemo(
    () =>
      map(list, (item) => {
        return {
          ...item,
          level: transformToCapitalize(item.level),
          expirationDate: item.skill?.isPermanent ? 'N/A' : item.expirationDate,
          sx: [
            (item.warning as any) === 'expireSoon' && {
              backgroundColor: `${theme.palette.colors.utility.warning.utility_warning_50} !important`,
            },
            (item.warning as any) === 'expired' && {
              backgroundColor: `${theme.palette.colors.utility.error.utility_error_50} !important`,
            },
            (item.warning as any) === 'trained' && {
              backgroundColor: `${theme.palette.colors.utility.success.utility_success_50} !important`,
            },
          ],
          attachment:
            item.attachments &&
            item.attachments.length &&
            item.attachments[0].attachment ? (
              <ThreeDotsMenu
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  item.attachments &&
                    setAttachmentToShow(
                      item.attachments.map((item, i) => ({
                        fileUrl: item.attachment,
                        fileName: item.name ?? `Attachment_${i}`,
                      })),
                    );
                }}
              />
            ) : null,
        };
      }),
    [
      list,
      theme.palette.colors.utility.error.utility_error_50,
      theme.palette.colors.utility.success.utility_success_50,
      theme.palette.colors.utility.warning.utility_warning_50,
    ],
  );

  return (
    <>
      <PageContentWithTopToolbar>
        <PageContentChildContainer fullHeight={false}>
          <EnhancedTable
            ignoreGlobalSitesOnExport
            data={computedList}
            count={count}
            selectIndex="id"
            isTableDataLoading={
              isTableDataLoading || isAttachmentsRequestsInProgress
            }
            tableName={t('emp_skills.table_name')}
            headCells={headCells}
            filters={filters}
            onSync={onSync}
            onUpdate={allowedToUpdateEmployeeSkill ? handleUpdating : undefined}
            include={defaultFilter.include}
            exportProps={exportProps}
            deleteModelName={ModelsToDelete.EmployeeSkill}
          />
        </PageContentChildContainer>
      </PageContentWithTopToolbar>

      <FilesDownloaderDrawer
        filesConfig={attachmentToShow}
        isOpen={Boolean(attachmentToShow.length)}
        onClose={() => setAttachmentToShow([])}
      />

      <UpdateEmployeesSkills
        employeesSkillsIds={selectedEmployeesSkills}
        isOpen={isUpdateEmployeesSkillFlexVisible}
        onClose={() => setIsUpdateEmployeesSkillFlexVisible(false)}
        filterList={filterList.filter ?? {}}
        filterCount={filterCount.filter}
        onRequestsWithAttachmentsStart={() =>
          setIsAttachmentsRequestsInProgress(true)
        }
        onRequestsWithAttachmentsEnd={() =>
          setIsAttachmentsRequestsInProgress(false)
        }
      />
    </>
  );
};
