import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import noop from 'lodash/noop';
import EnhancedTable, {
  HeadCell,
  ITableSyncProps,
} from '../../../../../components/EnhancedTable';
import {
  exportNewPayboardWeekRequest,
  getNewPayboardWeekRequest,
  getNewPayboardWeekCountRequest,
} from '../../../../../modules/actions';
import {
  getPayboardWeekCount,
  getNewPayboardWeeks,
  getIsNewPayboardWeekDataRequestInProgress,
} from '../../../../../modules/selectors/payboard';
import { ITableFilter } from '../../../../../components/EnhancedTable/EnhancedTableFilter';
import {
  useFetchActiveDepartmentsCombobox,
  useCreateExportProps,
} from '../../../../../modules/utils/hooks';
import { useTranslation } from 'react-i18next';
import { getISOWeek, getYear } from 'date-fns';
import { getNow } from 'src/modules/utils/dateWrapper';
import { getGloballySelectedSites } from 'src/modules/selectors/site';
import { isTableHasDefaultOrder } from 'src/modules/utils/hooks/table';

const defaultOrder = ['yearWeek DESC, lastName ASC, firstName ASC'];

const currentDate = getNow();
const initialFilterData = {
  yearWeek: {
    eq: Number(
      `${getYear(currentDate)}${String(getISOWeek(currentDate)).padStart(
        2,
        '0',
      )}`,
    ),
  },
};

const PayboardKronos = () => {
  const { t } = useTranslation();
  const fetchDepartmentsCombobox = useFetchActiveDepartmentsCombobox();

  const globalSitesIds = useSelector(getGloballySelectedSites);

  const isTableDataLoading = useSelector(
    getIsNewPayboardWeekDataRequestInProgress,
  );

  // make request to fetch departments from the server if we don't have them in the store
  useEffect(() => {
    fetchDepartmentsCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const newWeekData = useSelector(getNewPayboardWeeks);
  const count = useSelector(getPayboardWeekCount, shallowEqual);
  const exportProps = useCreateExportProps(exportNewPayboardWeekRequest);

  // create dispatcher
  const dispatcher = useDispatch();

  const headCells: HeadCell[] = [
    { id: 'endDate', disablePadding: false, label: t('payboard.end_date') },
    {
      id: 'year',
      disablePadding: false,
      label: t('payboard.year'),
      orderByAnotherField: 'yearWeek',
    },
    {
      id: 'week',
      disablePadding: false,
      label: t('payboard.week'),
      orderByAnotherField: 'yearWeek',
    },
    {
      id: 'companyShortName',
      disablePadding: false,
      label: t('payboard.company_short_name'),
    },
    { id: 'badgeId', disablePadding: false, label: t('payboard.badge_id') },
    {
      id: 'lastName',
      disablePadding: false,
      label: t('payboard.last_name'),
    },
    {
      id: 'firstName',
      disablePadding: false,
      label: t('payboard.first_name'),
    },
    {
      id: 'employeeId',
      disablePadding: false,
      label: t('payboard.employee_id'),
    },
    {
      id: 'eBaseRate',
      disablePadding: false,
      label: t('payboard.e_base_rate'),
    },
    {
      id: 'siteId',
      disableSorting: true,
      disablePadding: false,
      label: t('payboard.site_id'),
    },
    {
      id: 'siteName',
      disableSorting: true,
      disablePadding: false,
      label: t('payboard.site_name'),
    },
    {
      id: 'payStatementType',
      disablePadding: false,
      label: t('payboard.pay_statement_type'),
    },
    {
      id: 'edt',
      disablePadding: false,
      label: t('payboard.e_d_t'),
    },
    {
      id: 'edtCode',
      disablePadding: false,
      label: t('payboard.e_d_t_code'),
      disableSorting: true,
    },
    {
      id: 'eHours',
      disablePadding: false,
      label: t('payboard.e_hours'),
    },
    {
      id: 'edtAmount',
      disablePadding: false,
      label: t('payboard.e_d_t_amount'),
    },
    {
      id: 'totalPay',
      disablePadding: false,
      label: t('payboard.total_pay'),
    },
  ];

  // table filters
  const filters: ITableFilter[] = [
    {
      name: 'siteId',
      label: t('payboard.site'),
      operator: 'eq',
      type: 'comboboxSites',
    },
    {
      name: 'lastName',
      label: t('payboard.last_name'),
      operator: 'like',
    },
    {
      name: 'firstName',
      label: t('payboard.first_name'),
      operator: 'like',
    },
    {
      name: 'employeeId',
      label: t('payboard.employee_id'),
      operator: 'like',
    },
    {
      name: 'badgeId',
      label: t('payboard.badge_id'),
      operator: 'like',
    },
    {
      name: 'endDate',
      label: t('payboard.end_date'),
      operator: 'eq',
      type: 'date',
    },
    {
      name: 'yearWeek',
      label: t('payboard.year_week'),
      operator: 'eq',
      type: 'comboboxYearWeek',
    },
    {
      name: 'companyShortName',
      label: t('payboard.company_short_name'),
      operator: 'like',
    },
    {
      name: 'payStatementType',
      label: t('payboard.pay_statement_type'),
      operator: 'like',
    },
    {
      name: 'edtCode',
      label: t('payboard.e_d_t_code'),
      operator: 'like',
    },
    {
      name: 'eHours',
      label: t('payboard.e_hours'),
      operator: 'like',
    },
  ];

  // make request to fetch payboards when component is mounted
  useEffect(() => {
    dispatcher(
      getNewPayboardWeekRequest({
        filter: {
          where: {
            ...initialFilterData,
            siteId: { inq: globalSitesIds },
          },
          order: defaultOrder,
        },
      }),
    );
    // get total count
    dispatcher(
      getNewPayboardWeekCountRequest({
        filter: {
          where: {
            ...initialFilterData,
            siteId: { inq: globalSitesIds },
          },
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // dispatch the action only once

  // handle table synchronization
  const onSync = (props: ITableSyncProps) => {
    const { order: tableOrder, page, rowsPerPage, where: rawWhere } = props;

    const where = {
      ...rawWhere,
      siteId: (rawWhere as any)?.siteId ?? { inq: globalSitesIds },
      yearWeek: (rawWhere as any)?.yearWeek ?? initialFilterData.yearWeek,
    };

    const order = isTableHasDefaultOrder(tableOrder)
      ? defaultOrder
      : tableOrder;

    const filter = {
      offset: page * rowsPerPage,
      where,
      order,
    };

    dispatcher(
      getNewPayboardWeekRequest({
        filter,
      }),
    );
    // update count accordingly to applied filters
    dispatcher(
      getNewPayboardWeekCountRequest({
        filter: { where },
      }),
    );

    // updateFilterDataInQueryParams(filter);
  };

  return (
    <EnhancedTable
      disableQsFilters
      data={newWeekData.items}
      summaryRows={newWeekData.summary}
      isTableDataLoading={isTableDataLoading}
      count={count}
      selectIndex="" // if we pass an empty string it will use loop index for the key
      tableName={t('payboard.new_week_table_name')}
      headCells={headCells}
      filters={filters}
      disableSelection={true}
      onSync={onSync}
      onRowClick={noop}
      initialFilterData={initialFilterData}
      exportProps={exportProps}
      additionalWhereForExport={{ ...initialFilterData }}
      disablePagination
    />
  );
};

export default PayboardKronos;
